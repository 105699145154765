<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 问答分类 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="editFilling">新建</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :rowKey="(item) => item.answerId"
      :columns="columns"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText">
          <span
            v-if="!(pageNumber == 1 && index == 0)"
            @click="moveup(row.answerId)"
            >上移 |</span
          >

          <span
            v-if="(pageNumber - 1) * pageSize + index + 1 < total"
            @click="movedown(row.answerId)"
            >下移 |</span
          >
          <span @click="editItem(row)">编辑</span> |
          <span @click="delItem(row.answerId)">删除</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width="500px"
      title="新建分类"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name all_required">分类名称：</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入分类名称"
              v-model="sortObj.categoryName"
            />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name all_required">分类描述：</span>
          <div class="right_Div">
            <a-textarea
              placeholder="请简单对分类进行描述"
              :rows="4"
              style="resize: none"
              v-model="sortObj.info"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="editOk">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "分类名称",
    align: "center",
    width: "200px",
    dataIndex: "categoryName",
    scopedSlots: { customRender: "categoryName" },
  },
  {
    title: "分类描述",
    align: "center",
    dataIndex: "info",
  },
  {
    title: "创建时间",
    align: "center",
    width: "200px",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      answerId: "", // 分类id
      sort: "", // 位置顺序
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      sortObj: {
        info: "", // 描述
        categoryName: "", // 名称
      },
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    // 新建
    editOk() {
      if (!this.sortObj.categoryName) {
        this.$message.warn("分类名称不能为空！");
        return false;
      }
      if (!this.sortObj.info) {
        this.$message.warn("分类描述不能为空！");
        return false;
      }
      this.PreventLoad = true;
      this.$ajax({
        url: this.answerId
          ? "/hxclass-management/information/answer/category"
          : "/hxclass-management/information/answer/category",
        method: this.answerId ? "put" : "post",
        params: this.answerId
          ? {
              ...this.sortObj,
              answerId: this.answerId,
              sort: this.sort,
            }
          : this.sortObj,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.editCancel();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上移
    moveup(e) {
      let previndex = '';
      this.tableData.map((item,index)=>{
        if(item.answerId == e){
          previndex = index-1;
        }
      })
      // console.log(previndex)
      this.$ajax({
        url: "/hxclass-management/information/answer/category/up?id=" + e + '&oldId='+this.tableData[previndex].answerId,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 下移
    movedown(e) {
      let previndex = '';
      this.tableData.map((item,index)=>{
        if(item.answerId == e){
          previndex = index+1;
        }
      })
      // console.log(previndex)
      this.$ajax({
        url: "/hxclass-management/information/answer/category/down?id=" + e + '&oldId='+this.tableData[previndex].answerId,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 编辑
    editItem(e) {
      this.answerId = e.answerId;
      this.sort = e.sort;
      this.sortObj.info = e.info;
      this.sortObj.categoryName = e.categoryName;
      this.edit = true;
    },
    // 删除标签
    delItem(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/information/answer/category?id=" + e,
              method: "delete",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
                _this.getManageList();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
    // delItem(e) {
    //   this.$ajax({
    //     url: "/hxclass-management/information/answer/category?id=" + e,
    //     method: "delete",
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.getManageList();
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    editCancel(e) {
      this.answerId = "";
      this.sort = "";
      this.sortObj = {
        categoryName: "",
        info: "",
      };
      this.edit = false;
    },
    editFilling() {
      this.edit = true;
    },
    getManageList() {
      this.$ajax({
        url: "/hxclass-management/information/answer/category/list",
        method: "get",
        params: {
          page: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          if (!(res.data.length % this.pageSize) && this.pageNumber != 1) {
            if (res.data.length / this.pageSize < this.pageNumber) {
              this.pageNumber -= 1;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 获取分类列表
    this.getManageList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
}
.all_content_box {
  textarea.ant-input,
  .all_input {
    width: 340px;
  }
  .all_left_name {
    text-align: right;
    width: 80px;
  }
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}
/deep/ .ant-btn {
  margin-right: 24px;
}
</style>
